import { memo, useCallback } from 'react';
import { cn, Gallery } from '@divlab/divanui';

import * as SearchStore from '@Stores/Search';
import styles from './TipList.module.css';

import type { MouseEvent, FC, HTMLAttributes } from 'react';
import type { TipData } from '@Types/Search';

interface TipsListProps extends HTMLAttributes<HTMLDivElement> {
  tips: TipData[];
  onTapClick?: (event: MouseEvent, value: TipData) => void;
}

const TipList: FC<TipsListProps> = (props) => {
  const { className, tips, onTapClick, ...restProps } = props;

  const handleChangeRequest = useCallback(
    (event: MouseEvent, value: TipData) => {
      onTapClick?.(event, value);
      SearchStore.search({ term: value.relatedSearch });
    },
    [onTapClick],
  );

  return (
    <Gallery className={cn(styles.tips, className)} cnSlide={styles.tip} {...restProps}>
      {tips.map((tip) => (
        <div key={tip.tap}>
          <div className={styles.name} onClick={(event) => handleChangeRequest(event, tip)}>
            {tip.tap}
          </div>
        </div>
      ))}
    </Gallery>
  );
};

export default memo(TipList);
