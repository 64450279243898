import { useEffect, useMemo, useState } from 'react';
import { cn } from '@divlab/divanui';

import useTranslation from '@Queries/useTranslation';
import { useDevPanel } from '@Stores/DevPanel';
import styles from './Timer.module.css';

import type { TimerData } from '@Types/Base';
import type { FC, HTMLAttributes } from 'react';

export interface TimerProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  timer: TimerData;
}

const Timer: FC<TimerProps> = (props) => {
  const { className, timer, ...restProps } = props;
  const { timestamp } = timer;
  const { currentDate: devCurrentDate } = useDevPanel();
  const currentDate = useMemo(() => devCurrentDate || new Date(), [devCurrentDate]);
  const { t } = useTranslation();
  const [time, setTime] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    function formatTimeItem(timeItem: number) {
      const item = Math.floor(timeItem);
      const result = item < 0 ? 0 : item;
      return result < 10 ? `0${item}` : `${item}`;
    }

    function setTimer() {
      const endTime = timestamp;

      if (endTime - Number(currentDate) > 0) {
        const timeDiff = endTime - Number(currentDate);
        const hourInMs = 1000 * 60 * 60;

        setTime({
          days: formatTimeItem(timeDiff / (hourInMs * 24)),
          hours: formatTimeItem((timeDiff / hourInMs) % 24),
          minutes: formatTimeItem((timeDiff / 1000 / 60) % 60),
          seconds: formatTimeItem((timeDiff / 1000) % 60),
        });
      }
    }

    const intervalId = setInterval(setTimer, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timestamp, currentDate]);

  return (
    <div {...restProps} className={cn(styles.timer, className)}>
      <span className={styles.rank}>{`${time.days} ${t('ui.timer.days')}`}</span>{' '}
      <span className={styles.rank}>{`${time.hours} ${t('ui.timer.hours')}`}</span>{' '}
      <span className={styles.rank}>{`${time.minutes} ${t('ui.timer.minutes')}`}</span>{' '}
      {timer.withSeconds && (
        <span className={styles.rank}>{`${time.seconds} ${t('ui.timer.seconds')}`}</span>
      )}
    </div>
  );
};

export default Timer;
