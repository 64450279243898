import { memo, useCallback, useEffect, useMemo, useRef, useState, lazy } from 'react';
import { cn } from '@divlab/divanui';

import * as HeaderStore from '@Stores/Header';
import * as SearchStore from '@Stores/Search';
import * as Meta from '@Queries/Meta';
import { useComponentStorage } from '@Stores/ComponentStorage';
import { useRequest } from '@Contexts/Request';
import { useDeps } from '@Contexts/DI';
import Logotype from '@Components/Logotype';
import LogotypeDivanClub from '@Components/LogotypeDivanClub';
import Link from '@Navigation/Link';
import useRouteMatchers from '@Navigation/useRouteMatchers';
import useMedias from '@Hooks/useMedias';
import useNavigation from '@Navigation/useNavigation';
import InputSearch from '../../elems/InputSearch';
import UserMenu from '../UserMenu';
import FirstLevelNav from '../FirstLevelNav';
import SecondLevelNav from '../SecondLevelNav';
import SearchDropDown from '../SearchDropDown/SearchDropDown';
import Form from '@UI/Form';
import Back from '../Back';
import Suspense from '@Components/Suspense';
import styles from './HeaderDesktop.module.css';

import type { FC, HTMLAttributes, CSSProperties } from 'react';
import type { MainMenuItem } from '@Types/Layout';

const RegionQuestionPopup = lazy(() => import('@Layouts/elems/RegionQuestionPopup'));

export interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  special: MainMenuItem[];
}

const HeaderDesktop: FC<HeaderProps> = (props) => {
  const { special } = props;
  const components = useComponentStorage();
  const { isOnlyDesktop } = useMedias();
  const { fixed, isOpenedSearch } = HeaderStore.useMenu();
  const { toplineHeight } = HeaderStore.useSizes();
  const { isIndex, isDivanClub } = useRouteMatchers();
  const { country, origin } = useRequest();
  const { data: regionPopup } = Meta.useRegionQuestion();
  const [hiddenSecondLevel, setHiddenSecondLevel] = useState(fixed);
  const headerRef = useRef<HTMLDivElement>(null);
  const firstLevelRef = useRef<HTMLDivElement>(null);
  const meta = Meta.useMeta();
  const { region, isTerminal } = meta.data;
  const { result } = SearchStore.useSearch();
  const { correction, redirectUrl } = result;
  const { logger } = useDeps();
  const navigation = useNavigation();
  const hasRoundedItem = special.some((item) => item.parameters?.rounded);
  const needBack = isTerminal && isOnlyDesktop && !isIndex;

  const style = useMemo<CSSProperties>(() => {
    return {
      marginTop: fixed ? `-${toplineHeight}px` : null,
    };
  }, [fixed, toplineHeight]);

  const tryHideSecondLevel = useCallback(() => {
    setHiddenSecondLevel(() => fixed || isOpenedSearch);
  }, [fixed, isOpenedSearch]);

  const handleClickLink = useCallback(() => {
    HeaderStore.closeMenu();
  }, []);

  const calculate = useCallback(() => {
    HeaderStore.setSizes({ contentHeight: fixed ? 90 : 140 });
  }, [fixed]);

  const handleClickInputSearch = useCallback(async () => {
    if (isOpenedSearch) return;

    await SearchStore.search({ term: result.request });

    HeaderStore.openSearch();
  }, [isOpenedSearch, result.request]);

  const handleSearch = useCallback(
    async (e, data) => {
      e.preventDefault();
      try {
        const name = correction || data.search;
        const searchParams = new URLSearchParams({
          'ProductSearch[name]': name,
          no_cache: '1',
        });
        const url = redirectUrl || `${region.url}/search?${searchParams.toString()}`;

        SearchStore.setSearchHistory(name);

        await navigation.openPage({ url });
      } catch (err) {
        logger.log(err);
      } finally {
        HeaderStore.closeSearch();
      }
    },
    [correction, logger, navigation, redirectUrl, region.url],
  );

  // Скрываем/показываем второй уровень когда закрепляем/открепляем шапку
  useEffect(() => {
    tryHideSecondLevel();
  }, [fixed, tryHideSecondLevel]);

  // Рассчитываем высоту шапки
  useEffect(() => {
    calculate();
    window.addEventListener('resize', calculate);

    return () => {
      window.removeEventListener('resize', calculate);
    };
  }, [calculate]);

  return (
    <div
      className={cn(styles.header, {
        [styles.fixed]: fixed,
        [styles.hiddenSecondLevel]: hiddenSecondLevel,
      })}
      ref={headerRef}
      style={style}
    >
      <div
        className={cn(styles.firstLevel, styles.container, {
          [styles.withRoundedItem]: hasRoundedItem,
        })}
      >
        <div className={styles.headerIn} ref={firstLevelRef}>
          <div className={styles.maxWidth}>
            {regionPopup && (
              <Suspense fallback={null}>
                <RegionQuestionPopup data={regionPopup} className={styles.regionQuestionPopup} />
              </Suspense>
            )}
            <div className={cn(styles.wrapperLogotype)}>
              {needBack && <Back className={styles.back} />}
              <Link
                className={styles.slider}
                to='/'
                data-testid='logo-link'
                onClick={handleClickLink}
              >
                {isDivanClub ? (
                  <LogotypeDivanClub className={styles.logotypeDivanClub} />
                ) : (
                  <Logotype country={country} />
                )}
              </Link>
            </div>

            <FirstLevelNav onClickItem={handleClickLink} />

            <div
              itemScope
              itemType='https://schema.org/WebSite'
              className={styles.search}
              onMouseDown={handleClickInputSearch}
            >
              <meta itemProp='url' content={origin} />
              <Form
                name='search'
                onSubmit={handleSearch}
                itemProp='potentialAction'
                itemScope
                itemType='https://schema.org/SearchAction'
              >
                <meta itemProp='target' content={`${origin}/search?ProductSearch[name]={search}`} />
                <InputSearch active={isOpenedSearch} />
              </Form>
            </div>
          </div>

          <div className={styles.right}>
            <UserMenu className={styles.userMenu} />
          </div>
        </div>
      </div>
      {isOnlyDesktop && <SearchDropDown opened={isOpenedSearch} />}
      <div className={styles.secondLevel}>
        <div className={cn(styles.secondLevelContainer, styles.container)}>
          <SecondLevelNav
            items={special}
            className={cn({ [styles.alignCenter]: hasRoundedItem })}
            onClickItem={handleClickLink}
          />
        </div>
      </div>
      {components?.filters && components.filters}
    </div>
  );
};

export default memo(HeaderDesktop);
