import type { MetaPhoneData } from '@Types/Meta';

export function phoneToLink(phone: string | MetaPhoneData): string {
  if (typeof phone === 'object') {
    return `tel:+${phone.country}${phone.code}${phone.phone.replace(/\D/g, '')}`;
  }

  return `tel:+${phone.replace(/\D/g, '')}`;
}

export function phoneToText(phone: string | MetaPhoneData, mask = ''): string {
  if (typeof phone === 'object') {
    return `+${phone.country} (${phone.code}) ${phone.phone}`;
  }

  const digits = phone.replace(/\D/g, '').split('');

  if (mask.replace(/[^X]/g, '').length === digits.length) {
    return mask.replace(/X/g, () => digits.shift());
  }

  return phone;
}
