import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import * as ApiOrder from '@Api/Order';
import transformCart from '@Queries/Cart/helpers/transformCart';
import { useDeps } from '@Contexts/DI';
import useCartKeys from './useCartKeys';

/**
 * NOTE: Данный запрос возвращает персонализированную информацию, поэтому он не должен выполняться во время серверного рендера,
 * т.к. это лишает нас возможности кэшировать страницы для улучшения производительности
 */
function useCart() {
  const keys = useCartKeys();
  const { eventManager } = useDeps();

  const query = useQuery({
    queryKey: keys,
    queryFn: async () => {
      const cart = await ApiOrder.getCartInfo();
      return transformCart({ cart: { cart } });
    },
    gcTime: 0,
    enabled: true,
    placeholderData: (previousData) => previousData,
  });

  useEffect(() => {
    if (!query.data) return;

    eventManager.dispatch('cart.load', query.data);
  }, [eventManager, query.data]);

  return query;
}

export default useCart;
