import { useEffect } from 'react';

import { useAppConfig } from '@Contexts/AppConfig';
import { useDevPanel } from '@Stores/DevPanel';

export const ReactScan = () => {
  const { isDevelopment } = useAppConfig();
  const { isReactScanActive } = useDevPanel();
  useEffect(() => {
    // Make sure to run react-scan only after hydration
    if (isDevelopment) {
      import('react-scan')
        .then(({ scan }) => {
          scan({
            enabled: isReactScanActive,
            trackUnnecessaryRenders: true,
            showToolbar: isReactScanActive,
          });
        })
        .catch(() => {});
    }
  }, [isDevelopment, isReactScanActive]);

  return null;
};
