import * as ApiPromo from '@Api/Promo';
import * as ApiMeta from '@Api/Meta';
import * as ApiCategory from '@Api/Category';
import * as Api from '@Api/index';
import * as ApiWiki from '@Api/Wiki';
import * as ApiCompare from '@Api/Compare';
import * as ApiOrder from '@Api/Order';
import * as ApiVisit from '@Api/VisitShowroom';
import ApiCart from '@Api/Cart';
import ApiSite from '@Api/Site';
import CabinetApi from '@Api/Cabinet';
import DeliveryTrackingApi from '@Api/DeliveryTracking';
import cutOutRegionSlug from '@Utils/cutOutRegionSlug';
import { BackendUnavailableError } from '@Common/errors';
import generateQueryKeys from '../Queries/helpers/generateQueryKeys';
import transformUrl from './transformUrl';
import { getInlineBannersKeys } from '@Queries/Category/useInlineBanners';
import { getDirectCreditKeys } from '@Queries/Order/useDirectCreditStatus';

import type { QueryClient } from '@tanstack/react-query';
import type { CountryCode, PageMeta } from '@Types/Base';
import type { ProfileData } from '@Types/Profile';
import type { ModalId } from '@Stores/ModalWindows/typings';

export interface TranslationParams {
  country: CountryCode;
  lang: string;
  pathname: string;
}

interface PrefetchCategoryParams {
  pathname: string;
  search: string;
  slug: string;
  pageNumber: number;
}

interface InfiniteWikiArticles {
  categoryId?: string | number;
  tagId?: string | number;
  pageNumber?: number;
}

interface PrefetchOrdersParams {
  page: number;
  search: string;
  profile: ProfileData;
}

interface PrefetcherParams {
  request: Request;
  queryClient: QueryClient;
  country: string;
  region: string;
}

interface PrefetchInlineBannersParams {
  queryClient: QueryClient;
  search: string;
  slug: string;
}

type Prefetcher = (params: PrefetcherParams) => void;

// Данные для страницы
export const prefetchPage = async (
  url: string,
  queryClient: QueryClient,
  queryKeys: string[] = [],
) => {
  const { pathname, search } = new URL(url);
  const { headers, proxyOrigin, region } = Api.getRequest();
  const resultUrl = `${pathname}${search}`;
  const keys = generateQueryKeys({
    keys: ['page', region, ...queryKeys],
    pathname,
    search,
  });
  const queryUrl = `${proxyOrigin}/backend${resultUrl}`;
  const cache = queryClient.getQueryData(keys);
  const isBrowser = typeof document !== 'undefined';

  // Если данные уже есть в кэше, то ничего дополнительно делать не нужно
  if (cache) {
    return Promise.resolve();
  }

  let redirectResponse: Response;

  await queryClient
    .fetchQuery({
      queryKey: keys,
      queryFn: async () => {
        let res: Response;
        if (isBrowser) {
          res = await fetch(queryUrl, {
            headers: { ...headers, 'X-Request-Type': 'page' },
            credentials: 'include',
          });
        } else {
          res = await fetch(queryUrl, {
            headers: { ...headers, 'X-Request-Type': 'page' },
            redirect: 'manual',
          });
        }

        // Сервер может отправить нас на другую страницу
        const location = res.headers.get('Location');

        if (res.redirected || location) {
          const redirectUrl = location ? new URL(location, proxyOrigin) : new URL(res.url);
          const to = `${redirectUrl.pathname.slice('/backend'.length)}${redirectUrl.search}`;

          redirectResponse = new Response('', {
            // Статус ответа в браузере может быть 200 и редиректа не произойдет
            status: res.status > 300 ? res.status : 302,
            headers: {
              Location: to,
            },
          });
          //Пробрасываем ошибку чтобы не наполнять кэш и выйти из выполнения запроса
          throw new Error('redirect');
        }

        // Отдельный тип ошибки для случая, когда бэкенд недоступен
        if (res.status >= 500) {
          throw new BackendUnavailableError();
        }

        // В случае успешного ответа заполняем кэш react-query уже полученными данными
        // Для 404-й страницы также возвращаются данные о странице
        if ([200, 404].includes(res.status)) {
          const data = await res.json();

          // Подменяем региональный слаг в canonical ссылке
          const pageMeta: PageMeta = data.body?.data.meta;
          const canonicalTag = pageMeta?.tags.find(
            (tag) => tag.tag === 'link' && tag.props?.rel === 'canonical',
          );

          if (canonicalTag) {
            const canonicalUrl = new URL(canonicalTag.props.href);
            const newPathname = transformUrl({
              url: canonicalUrl.pathname,
              targetRegion: region,
            });

            canonicalTag.props.href = `${canonicalUrl.origin}${newPathname}`;
          }

          return { ...data.body?.data, page: data.page };
        }
      },
    })
    .catch((err: Error) => {
      if (err.name === 'BackendUnavailableError') {
        throw new BackendUnavailableError();
      }
    });

  if (redirectResponse) {
    return redirectResponse;
  }
};

// Данные для локализации страницы
export const prefetchTranslation = (url: string, queryClient: QueryClient) => {
  const { country, language } = Api.getRequest();
  const { pathname } = new URL(url);

  const translationParams: TranslationParams = {
    country,
    lang: language.id,
    pathname,
  };

  const keys = ['translation', 'page', translationParams];

  return queryClient.prefetchQuery({
    queryKey: keys,
    queryFn: () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return ApiMeta.getPageTranslation(translationParams);
    },
  });
};

// Информация о промо-категориях товаров
export const prefetchInfinitePromoCategory = (
  params: PrefetchCategoryParams,
  queryClient: QueryClient,
) => {
  const { pathname, search, slug, pageNumber } = params;
  const { region } = Api.getRequest();
  const keys = generateQueryKeys({
    keys: ['infinitePromoCategory', region, slug],
    pathname,
    search,
  });
  const cachedValue = queryClient.getQueryData(keys);

  if (cachedValue) return Promise.resolve(cachedValue);

  return queryClient.prefetchInfiniteQuery({
    queryKey: keys,
    queryFn: ({ pageParam }) => {
      return ApiCategory.getPromoProducts({
        slug,
        page: pageParam || pageNumber,
        search,
        isInit: true,
      });
    },
    initialPageParam: 0,
  });
};

// Поиск
export const prefetchInfiniteSearch = (url: string, queryClient: QueryClient) => {
  const { pathname, search, searchParams } = new URL(url);
  const term = searchParams.get('ProductSearch[name]');
  const { region } = Api.getRequest();
  const keys = generateQueryKeys({
    keys: ['infiniteSearch', region],
    pathname,
    search,
  });
  const cachedValue = queryClient.getQueryData(keys);

  if (cachedValue) return Promise.resolve(cachedValue);

  return queryClient.prefetchInfiniteQuery({
    queryKey: keys,
    queryFn: ({ pageParam }) => {
      return ApiSite.searchProducts({
        term,
        page: pageParam,
        search,
      });
    },
    initialPageParam: 1,
  });
};

// Список статей в Идеях и трендах
export const prefetchInfiniteWikiArticles = (
  params: InfiniteWikiArticles,
  queryClient: QueryClient,
) => {
  const { categoryId, tagId, pageNumber } = params;
  const keys = ['infiniteWikiArticles', categoryId, tagId].filter(Boolean);
  const cachedValue = queryClient.getQueryData(keys);

  if (cachedValue) return Promise.resolve(cachedValue);

  return queryClient.prefetchInfiniteQuery({
    queryKey: keys,
    queryFn: ({ pageParam }) => {
      return ApiWiki.getWikiArticles({ page: pageParam || pageNumber, categoryId, tagId });
    },
    initialPageParam: 1,
  });
};

// Данные о списке сравнения товаров
export const prefetchCompare = (queryClient: QueryClient) => {
  const { region, language } = Api.getRequest();
  const keys = ['compare', region, language];

  return queryClient.prefetchQuery({
    queryKey: keys,
    queryFn: async () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return ApiCompare.getCompareProducts();
    },
  });
};

// Профиль пользователя
export const prefetchProfile = (queryClient: QueryClient) => {
  const { country } = Api.getRequest();
  const keys = ['profile'];
  const cabinetApi = new CabinetApi({ country });

  return queryClient.prefetchQuery({
    queryKey: keys,
    queryFn: async () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return cabinetApi.fetchProfile();
    },
  });
};

// Заказы пользователя
export const prefetchOrders = (params: PrefetchOrdersParams, queryClient: QueryClient) => {
  const { profile, page, search } = params;
  const keys = ['orders', page, search, { mindboxId: profile?.mindboxId }];
  const { country } = Api.getRequest();
  const cabinetApi = new CabinetApi({ country });

  return queryClient.prefetchQuery({
    queryKey: keys,
    queryFn: async () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return cabinetApi.fetchOrders({ page, search });
    },
  });
};

// Рекламации пользователя
export const prefetchComplaints = (profile: ProfileData, queryClient: QueryClient) => {
  const { country } = Api.getRequest();
  const cabinetApi = new CabinetApi({ country });
  const keys = ['complaints', { mindboxId: profile?.mindboxId }];

  return queryClient.prefetchQuery({
    queryKey: keys,
    queryFn: async () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return cabinetApi.fetchComplaints();
    },
  });
};

// История начисления бонусов пользователя
export const prefetchBonusHistory = (profile: ProfileData, queryClient: QueryClient) => {
  const { country } = Api.getRequest();
  const cabinetApi = new CabinetApi({ country });
  const keys = ['bonus-history', { mindboxId: profile?.mindboxId }];

  return queryClient.prefetchQuery({
    queryKey: keys,
    queryFn: async () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return cabinetApi.fetchBonusHistory();
    },
  });
};

// Мета данные
export const prefetchMetaData = (queryClient: QueryClient) => {
  const { region, secondaryRegion, language } = Api.getRequest();
  const keys = [
    'meta',
    {
      region,
      secondaryRegion,
      lang: language.id,
    },
  ];

  return queryClient.prefetchQuery({
    queryKey: keys,
    queryFn: async () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return ApiMeta.fetch();
    },
  });
};

// Данные Layout
export const prefetchLayoutData = (queryClient: QueryClient) => {
  const { region, language } = Api.getRequest();
  const keys = ['layout', { region, lang: language.id }];

  return queryClient.prefetchQuery({
    queryKey: keys,
    queryFn: async () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return ApiMeta.getLayout();
    },
  });
};

// Данные для переводов
export const prefetchUITranslation = (queryClient: QueryClient) => {
  const { language, country } = Api.getRequest();
  const params = { lang: language.id, country };

  const keys = ['translation', 'ui', params];

  return queryClient.prefetchQuery({
    queryKey: keys,
    queryFn: () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return ApiMeta.getUITranslation(params);
    },
  });
};

// Информация о промоматериалах
export const prefetchPromotions = (
  params: { listName: string; type: string },
  queryClient: QueryClient,
) => {
  const { type, listName } = params;
  const { country, language, region } = Api.getRequest();

  const keys = ['list-promotions', { type, country, lang: language.id, region, listName }];

  return queryClient.prefetchQuery({
    queryKey: keys,
    queryFn: async () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return ApiPromo.getList({
        type,
        country,
        lang: language.id,
        region,
        listName,
      });
    },
  });
};

// Информация о категорийной акции
export const prefetchCategoryPromotion = (params: { slug: string }, queryClient: QueryClient) => {
  const { slug } = params;
  const { country, language, region } = Api.getRequest();
  const keys = ['category-promotions', { slug, country, lang: language.id, region }];

  return queryClient.prefetchQuery({
    queryKey: keys,
    queryFn: async () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return ApiPromo.getByCategory({
        slug,
        country,
        lang: language.id,
        region,
      });
    },
  });
};

export const prefetchInfoStripe: Prefetcher = (params) => {
  const { request, queryClient } = params;
  const { pathname } = new URL(request.url);
  const { region } = Api.getRequest();
  const keys = ['info-stripe', region, { pathname: cutOutRegionSlug(pathname) }];

  return queryClient.prefetchQuery({
    queryKey: keys,
    queryFn: async () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return ApiMeta.getInfoStripe(pathname);
    },
  });
};

export const prefetchAllRegions = (queryClient: QueryClient) => {
  const { country, region, mainRegion } = Api.getRequest();
  const keys = ['allRegions'];

  // Only for main region in Russia
  if (country === 'RUS' && region === mainRegion) {
    return queryClient.prefetchQuery({
      queryKey: keys,
      queryFn: async () => {
        const cache = queryClient.getQueryData(keys);

        if (cache) return Promise.resolve(cache);

        return ApiMeta.getAllRegions();
      },
    });
  }

  return Promise.resolve();
};

export const prefetchSEOCategories = (queryClient: QueryClient) => {
  const { country, region, mainRegion } = Api.getRequest();
  const keys = ['seo-categories'];

  // Only for main region in Russia
  if (country === 'RUS' && region === mainRegion) {
    return queryClient.prefetchQuery({
      queryKey: keys,
      queryFn: async () => {
        const cache = queryClient.getQueryData(keys);

        if (cache) return Promise.resolve(cache);

        return ApiSite.getSEOCategories();
      },
    });
  }

  return Promise.resolve();
};

export const prefetchModalWindowTranslation = (modalId: ModalId, queryClient: QueryClient) => {
  const { country, language } = Api.getRequest();
  const queryParams = { lang: language.id, country, modalId };
  const keys = ['translation', 'modal-window', queryParams];

  return queryClient.prefetchQuery({
    queryKey: keys,
    queryFn: async () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return ApiMeta.getModalWindowTranslation(queryParams);
    },
  });
};

export const prefetchOrderSections = (queryClient: QueryClient) => {
  const { country, language, region } = Api.getRequest();
  const queryParams = { lang: language.id, country, region };
  const keys = ['order-sections', queryParams];

  return queryClient.prefetchQuery({
    queryKey: keys,
    queryFn: async () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return ApiOrder.getSections();
    },
  });
};

export const prefetchDeliveryTracking = (url: string, queryClient: QueryClient) => {
  const id = new URL(url).searchParams.get('id');

  const keys = ['delivery-order-state', id];

  const getOrderState = async () => {
    return DeliveryTrackingApi.getOrderState({ id });
  };

  return queryClient.prefetchQuery({ queryKey: keys, queryFn: getOrderState });
};

export const prefetchShowroomVisit = (slug: string, queryClient: QueryClient) => {
  const url = `/visit/share/${slug}`;
  const keys = ['page', 'visit', slug];

  return queryClient.prefetchQuery({
    queryKey: keys,
    queryFn: async () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return ApiVisit.getVisit({ url });
    },
  });
};

export const prefetchInlineBanners = (params: PrefetchInlineBannersParams) => {
  const { queryClient, slug, search } = params;

  const keys = getInlineBannersKeys({ slug, search });
  const inputParams = {
    slug,
    search,
  };

  return queryClient.prefetchQuery({
    queryKey: keys,
    queryFn: async () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return ApiCategory.getInlineBanners(inputParams);
    },
  });
};

export const prefetchOrderSuccess = (url: string, queryClient: QueryClient) => {
  const orderId = url.split('?')[1]?.match(/\d+/g)?.[0];

  const keys = ['cart-success', orderId];
  return queryClient.prefetchQuery({
    queryKey: keys,
    queryFn: async () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return ApiCart.success({ orderId });
    },
  });
};

export const prefetchDirectCreditStatus = (params) => {
  const { queryClient, uuid } = params;
  const { region, country } = Api.getRequest();

  const keys = getDirectCreditKeys({ region, country, uuid });

  return queryClient.prefetchQuery({
    queryKey: keys,
    queryFn: async () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return ApiOrder.getDirectCreditStatus(uuid);
    },
  });
};
