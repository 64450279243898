import { createStore, update } from '@kundinos/nanostores';
import { useStore } from '@kundinos/nanostores/react';

interface IDevPanelStore {
  currentDate: Date;
  timeDifference: number;
  isReactScanActive: boolean;
}

export const devPanelStore = createStore<IDevPanelStore>(() => {
  devPanelStore.set({
    currentDate: undefined,
    timeDifference: 0,
    isReactScanActive: false,
  });
});

export function setCurrentDate(date: Date, queryClient) {
  update(devPanelStore, (value) => ({
    ...value,
    currentDate: date,
    timeDifference: +date - Date.now(),
  }));
  setTimeout(() => {
    queryClient.invalidateQueries({ queryKey: ['category-promotion'] });
  }, 0);
}

export function setReactScanState(state: boolean) {
  update(devPanelStore, (value) => ({
    ...value,
    isReactScanActive: state,
  }));
}

export function useDevPanel() {
  const data = useStore(devPanelStore);
  const currentDateInSec = Math.floor(+devPanelStore.value.currentDate / 1000).toString();

  return { ...data, currentDateInSec, setReactScanState };
}

export function getCurrentDateInSec() {
  if (devPanelStore.value?.currentDate === undefined) {
    return undefined;
  }
  return parseInt((+devPanelStore.value.currentDate / 1000).toString()).toString();
}
