import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';

export interface AdditionalData {
  id: number;
  quantity: number;
}

export interface ModuleData {
  id: number;
  quantity: number;
}

export interface Params {
  productIds: number[];
  search?: string;
  opts?: {
    additional?: AdditionalData[];
    module?: ModuleData[];
  };
}

interface BonusData {
  earnedAmount: number;
  extraBonus: boolean;
}

async function getBonuses(params: Params) {
  const { productIds, search } = params;
  const searchParams = new URLSearchParams(search);

  searchParams.set('shopProductId', productIds.toString());

  const url = `/cabinet/cart/get-configuration-bonus-amount?${searchParams.toString()}`;
  const res = await Api.queryProxi<ApiResponse<BonusData>>(url, {
    method: 'POST',
    body: JSON.stringify(params.opts),
  });

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getBonuses;
