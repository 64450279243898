import { useMutation } from '@tanstack/react-query';

import ApiCart from '@Api/Cart';
import { useRequest } from '@Contexts/Request';
import findPositionByProductId from './helpers/findPositionByProductId';
import transformCart from './helpers/transformCart';
import useCartKeys from './useCartKeys';

import type { CartData } from '@Types/Cart';

export interface Variables {
  productId: number;
  options: { isRelated: boolean };
}

export const useRemoveFromCart = () => {
  const { queryClient } = useRequest();
  const keys = useCartKeys();
  const cartData: CartData = queryClient.getQueryData(keys);

  const mutation = useMutation<CartData, Error, Variables>({
    mutationFn: async ({ productId, options }) => {
      const position = findPositionByProductId(productId, cartData);
      const res = await ApiCart.remove({ cartPositionId: position.id });

      return transformCart({ cart: res, options });
    },
    mutationKey: ['removeFromCart'],
    onMutate: async ({ productId }) => {
      const position = findPositionByProductId(productId, cartData);

      if (!position) return;

      await queryClient.cancelQueries({ queryKey: keys });

      // Снимок предыдущего значения
      const prevCart: CartData = queryClient.getQueryData(keys);

      const newPositionsList = prevCart.positions.filter((pos) => position.id !== pos.id);

      const removedPositionsList = prevCart.removedPositions.filter(
        (pos) => pos.id !== position.id,
      );

      const newCart = {
        ...prevCart,
        positions: newPositionsList,
        removedPositions: removedPositionsList,
      };

      // Оптимистическое обновление
      queryClient.setQueryData(keys, () => {
        return newCart;
      });

      // Возвращаем объект контекста с зафиксированным значением
      return { prevCart };
    },
    onError: (err, data, context: { prevCart: CartData }) => {
      queryClient.setQueryData(keys, context.prevCart);
    },
    onSuccess: (cart) => {
      queryClient.setQueryData(keys, (prev: CartData) => ({ ...prev, ...cart }));
      queryClient.invalidateQueries({ queryKey: ['cartShortInfo'], refetchType: 'all' });
    },
  });

  return mutation;
};

export default useRemoveFromCart;
