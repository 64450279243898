import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { cn } from '@divlab/divanui';
import { IconSearch, IconChevronBottom } from '@divlab/divanui/icons';

import * as HeaderStore from '@Stores/Header';
import * as SearchStore from '@Stores/Search';
import * as ModalWindows from '@Stores/ModalWindows';
import { useRequest } from '@Contexts/Request';
import Logotype from '@Components/Logotype';
import SmartRender from '@Components/SmartRender';
import LogotypeDivanClub from '@Components/LogotypeDivanClub';
import Link from '@Navigation/Link';
import useRouteMatchers from '@Navigation/useRouteMatchers';
import FeedbackPhone from '@Components/FeedbackPhone';
import UserMenu from '../UserMenu';
import Burger from '../Burger';
import styles from './HeaderTablet.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';

export interface HeaderTabletProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  withShadow?: boolean;
}

const HeaderTablet: FC<HeaderTabletProps> = (props) => {
  const { className, withShadow, ...restProps } = props;
  const { isDivanClub } = useRouteMatchers();
  const headerRef = useRef(null);
  const { country } = useRequest();
  const { fixed } = HeaderStore.useMenu();
  const isRus = country === 'RUS';

  const siteName = useMemo(() => {
    switch (country) {
      case 'RUS':
        return 'divan.ru';
      case 'BLR':
        return 'divan.by';
      case 'KAZ':
        return 'mebel.kz';
      case 'UZB':
        return 'divan.uz';
    }
  }, [country]);

  const { result } = SearchStore.useSearch();

  const handleClickSearch = useCallback(async () => {
    await SearchStore.search({ term: result.request });

    ModalWindows.open('Search');
  }, [result.request]);

  const handleClickPhone = (e: MouseEvent) => {
    if (isRus) return;

    e.preventDefault();
    ModalWindows.open('Feedback');
  };

  // Рассчитываем высоту шапки
  useEffect(() => {
    if (!headerRef.current) return;

    const rectHeader = headerRef.current.getBoundingClientRect();

    function calculate() {
      HeaderStore.setSizes({ contentHeight: rectHeader.height });
    }

    calculate();
    window.addEventListener('resize', calculate);

    return () => {
      window.removeEventListener('resize', calculate);
    };
  }, [fixed]);

  return (
    <div
      {...restProps}
      ref={headerRef}
      className={cn(
        styles.header,
        {
          [styles.withShadow]: withShadow,
        },
        className,
      )}
    >
      <div className={styles.col}>
        <Burger className={styles.burger} onClick={HeaderStore.openSideBar} />
        <IconSearch className={styles.iconSearch} onClick={handleClickSearch} />
        <div className={styles.phone}>
          <FeedbackPhone view='primary' onClick={handleClickPhone} />
          <SmartRender countries={['BLR', 'KAZ', 'UZB']}>
            <IconChevronBottom className={styles.callbackArrow} />
          </SmartRender>
        </div>
      </div>
      <div className={styles.col}>
        <Link
          className={cn(styles.logotype, { [styles.logotypeRu]: isRus })}
          to='/'
          aria-label={siteName}
          data-testid='logo-link'
        >
          {isDivanClub ? <LogotypeDivanClub /> : <Logotype country={country} />}
        </Link>
      </div>

      <div className={styles.col}>
        <UserMenu />
      </div>
    </div>
  );
};

export default memo(HeaderTablet);
