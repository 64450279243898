import * as Api from '@Api/index';
import { BackendError, IncorrectBackendResponseError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { RegionQuestionData } from '@Types/Region';

async function getRegionQuestion() {
  const { headers, regionId, country, device } = Api.getRequest();
  const queryUrl = `/region-popup`;
  let json: ApiResponse<RegionQuestionData> = null;
  let res = null;
  try {
    res = await fetch(queryUrl, {
      method: 'POST',
      body: JSON.stringify({ regionId, country, client: !!device?.bot ? 'bot' : 'user' }),
      headers: { ...headers, 'Content-Type': 'application/json' },
      credentials: 'include',
    });

    if (res.status !== 200) return null;

    json = await res.json();
  } catch (err) {
    throw new IncorrectBackendResponseError({ url: queryUrl, status: res.status });
  }

  if (!json.ok) throw new BackendError(queryUrl, res);

  return json.data;
}

export default getRegionQuestion;
