import objectHash from 'object-hash';

// TODO
// Это очень плохо! Но это позволяет получить единый способ фильтрации search-параметров
// В текущей архитектуре приложения сложно добиться такого же поведения без дублирования,
// будет сделано в последующих изменения во время внедрения Data Cache слоя
import { filterSearchParams } from '@Queries/helpers/generateQueryKeys';

import type { GenerateKeyParams } from '@Infrastructure/entities/cache';

/**
 * Содержит внутри себя логику, которая должна быть единой для всех реализаций кэша
 * Каждая из реализаций кэша должна наследоваться от данного класса и расширять его
 */
export class BaseCache {
  generateKey(params: GenerateKeyParams): string {
    const [pathname, searchString] = params.url.split('?');
    const searchParams = this.filterSearchParams(searchString);

    return objectHash({
      abtYandex: params.abtYandex,
      environment: params.environment,
      country: params.country,
      traits: params.traits,
      region: params.region,
      deviceType: params.deviceType,
      pathname: pathname,
      searchParams: searchParams,
    });
  }

  private filterSearchParams(input: string) {
    return filterSearchParams(input);
  }
}
