import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { CardBannerData, MainBannerData, Promotion } from '@Promo/typings';

interface Params {
  country: string;
  lang: string;
  region: string;
  type: string;
  listName: string;
  currentDate?: number;
}

export async function getList(
  params: Params,
): Promise<Promotion<MainBannerData | CardBannerData>[]> {
  const { mainRegion } = Api.getRequest();

  const searchParams = new URLSearchParams({
    country: String(params.country).toLowerCase(),
    lang: String(params.lang).toLowerCase(),
    region: String(params.region) === mainRegion ? '' : String(params.region),
    type: String(params.type).toLowerCase(),
    listName: String(params.listName),
    currentDate: params.currentDate ? String(params.currentDate) : '',
  });

  const url = `/promo/get-list?${searchParams.toString()}`;
  const res = await Api.queryProxi<ApiResponse<Promotion<MainBannerData | CardBannerData>[]>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}
