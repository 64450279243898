import type { Cache } from '@Infrastructure/entities/cache';
import type { FeatureFlagManager } from '@Infrastructure/entities/FeatureFlagManager';
import type { Manager } from 'client/Events/Manager';
import type { Logger } from '@Infrastructure/entities/logger';
import type { Analytics } from '@Infrastructure/entities/analytics';
import type { Telephony } from '@Infrastructure/entities/telephony';
import type { DeviceDetector } from '@Infrastructure/entities/DeviceDetector';
import type { CabinetApiMethods } from '@Types/Api';
import type { IRegionManager } from '@Infrastructure/entities/RegionsManager';
import type { Metrics } from '@Infrastructure/details/metrics/Metrics';
import type { Geo } from '@Infrastructure/details/geo/Geo';

export interface Deps {
  eventManager: Manager;
  logger: Logger;
  cabinetApi: CabinetApiMethods;
  analytics: Analytics;
  telephony: Telephony;
  cache: Cache;
  featureFlagManager: FeatureFlagManager;
  regionManager: IRegionManager;
  deviceDetector: DeviceDetector;
  metrics: Metrics;
  geo: Geo;
}

class DiContainer {
  private static instance: DiContainer;

  private deps: Deps;

  constructor() {
    if (DiContainer.instance) {
      return DiContainer.instance;
    }
    DiContainer.instance = this;
  }

  initDeps(deps: Deps) {
    this.deps = deps;
  }

  getDeps(): Deps {
    return this.deps;
  }
}

export default new DiContainer();
