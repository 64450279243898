import { useMutation } from '@tanstack/react-query';

import ApiCart from '@Api/Cart';
import { useRequest } from '@Contexts/Request';
import { useCartKeys } from '@Queries/Cart';

import type { CartData } from '@Types/Cart';

export const useCheckCertificate = () => {
  const { queryClient } = useRequest();
  const keys = useCartKeys();

  const mutation = useMutation({
    mutationFn: async () => {
      const data = await ApiCart.checkCertificate();

      return data;
    },
    onSuccess: (newCart) => {
      queryClient.setQueryData(keys, (prev: CartData) => ({
        ...prev,
        giftCertificate: newCart.giftCertificate,
      }));
    },
  });

  return mutation;
};

export default useCheckCertificate;
