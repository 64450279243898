import { forwardRef, memo, useCallback, useMemo, useRef } from 'react';
import { cn } from '@divlab/divanui';
import { IconChevronRight } from '@divlab/divanui/icons';

import * as HeaderStore from '@Stores/Header';
import PromoGrid from '@Promo/elems/PromoGrid';
import SaleBanner from '@Promo/elems/SaleBanner';
import { useDeps } from '@Contexts/DI';
import CardInView from '@Components/CardInView';
import Link from '@Navigation/Link';
import usePromotionsForMenu from '@Queries/usePromotionsForMenu';
import { useRequest } from '@Contexts/Request';
import Img from '@UI/Img';
import DropDownWrapper from '../DropDownWrapper';
import SingleBanner from './elems/SingleBanner';
import Rows from './elems/Rows';
import styles from './DropDown.module.css';

import type { BannerGalleryProps } from '@Promo/elems/SaleBanner';
import type { DropDownWrapperProps } from '../DropDownWrapper/DropDownWrapper';
import type { MouseEvent } from 'react';
import type { ProductsBannerData, Promotion } from '@Promo/typings';
import type { FilteredMenuItemsData, MainMenuItem } from '@Types/Layout';

export interface DropDownProps extends Omit<DropDownWrapperProps, 'children'> {
  className?: string;
  onClickLink?: (e: MouseEvent) => void;
}

const DEFAULT_ICON_SIZE = 45;

const DropDown = forwardRef<HTMLDivElement, DropDownProps>((props, ref) => {
  const { onClickLink, ...restProps } = props;
  const { firstLevel, secondLevel } = HeaderStore.useSelectedLevels();
  const { data: promotions } = usePromotionsForMenu();
  const { language, region } = useRequest();
  const { analytics } = useDeps();
  const timeoutSecondLevelItem = useRef<NodeJS.Timeout>(null);

  const isRooms = secondLevel.theme === 'grid';
  const isSale = secondLevel.parameters?.type === 'sale';
  const isNovelty = secondLevel.parameters?.type === 'novelty';
  const isPromotions = secondLevel.parameters?.type === 'promotion';
  const isCategories = !isRooms && !isPromotions && !isSale && !isNovelty;

  const handleMouseEnterSecondLevelItem = useCallback((e: MouseEvent, item: MainMenuItem) => {
    clearTimeout(timeoutSecondLevelItem.current);

    timeoutSecondLevelItem.current = setTimeout(() => {
      HeaderStore.setSelectedLevels({ secondLevel: item });
    }, 300);
  }, []);

  const handleMouseLeaveSecondLevelItem = useCallback(() => {
    clearTimeout(timeoutSecondLevelItem.current);
  }, []);

  const matchedPromotion = useMemo<Promotion<ProductsBannerData>>(() => {
    if (!promotions?.products) return;

    let secondLevelURL = secondLevel?.url || '';

    if (language.slug) {
      secondLevelURL = secondLevelURL.replace(`/${language.slug}`, '');
    }

    if (region) {
      secondLevelURL = secondLevelURL.replace(`/${region}`, '');
    }

    return (promotions?.products || []).find((promotion) => {
      return promotion.materials[0].path[1] === secondLevelURL;
    });
  }, [language.slug, promotions, region, secondLevel?.url]);

  const secondLvlItems = useMemo<FilteredMenuItemsData>(() => {
    const filteredItems = {
      common: [],
      items: [],
      banners: [],
    };

    secondLevel.children.forEach((item) => {
      if (item.theme === 'common') {
        return filteredItems.common.push(item);
      }

      if (item.icon?.url && item.parameters?.backgroundColor) {
        return filteredItems.banners.push(item);
      }

      return filteredItems.items.push(item);
    });

    return filteredItems;
  }, [secondLevel.children]);

  const columns = useMemo(() => {
    const array = secondLvlItems.items;
    const size = 2;
    const subarray = [];
    for (let i = 0; i < Math.ceil(array.length / size); i += 1) {
      subarray[i] = array.slice(i * size, i * size + size);
    }
    return subarray;
  }, [secondLvlItems.items]);

  const handleClickMiniBanner = useCallback(
    (e: MouseEvent, menuItem: MainMenuItem) => {
      if (onClickLink) onClickLink(e);

      analytics.dispatchEvent('header.clickMiniBanner', { item: menuItem });
    },
    [analytics, onClickLink],
  );

  const handleClickSidebarLink = useCallback(
    (e: MouseEvent, menuItem: MainMenuItem) => {
      if (onClickLink) onClickLink(e);

      const category = menuItem.url.includes('/catalog/room') ? 'MenuRooms' : 'MenuCatalog';
      analytics.dispatchEvent('header.clickMenu', { title: menuItem.name, category });
    },
    [analytics, onClickLink],
  );

  const handleClickRooms = useCallback(
    (e: MouseEvent, title: string) => {
      if (onClickLink) onClickLink(e);
      analytics.dispatchEvent('header.clickMenu', {
        title: `${secondLevel.name}/${title}`,
        category: 'MenuRooms',
      });
    },
    [analytics, secondLevel.name, onClickLink],
  );

  const handleClickCategory = useCallback(
    (e: MouseEvent, title: string) => {
      if (onClickLink) onClickLink(e);
      analytics.dispatchEvent('header.clickMenu', {
        title: `${secondLevel.name}/${title}`,
        category: 'MenuCatalog',
      });
    },
    [analytics, secondLevel.name, onClickLink],
  );

  return (
    <DropDownWrapper {...restProps} ref={ref}>
      <div className={styles.container}>
        <div className={styles.sidebar}>
          {firstLevel.children.map((item) => {
            return (
              <div key={item.id} className={styles.section}>
                {item.children.map((elem) => {
                  return (
                    <Link
                      key={elem.id}
                      to={elem.url}
                      view='primary'
                      className={cn(styles.menuItem, {
                        [styles.active]: elem.id === secondLevel.id,
                        [styles.promo]: elem.parameters?.promo,
                      })}
                      onClick={(e) => handleClickSidebarLink(e, elem)}
                      onMouseEnter={(e) => handleMouseEnterSecondLevelItem(e, elem)}
                      onMouseLeave={handleMouseLeaveSecondLevelItem}
                    >
                      <div className={styles.menuItemContainer}>
                        {elem.icon && (
                          <Img
                            cnImage={styles.iconImage}
                            className={styles.icon}
                            src={elem.icon.url}
                          />
                        )}
                        {elem.name}
                        <IconChevronRight className={styles.iconChevronRight} />
                      </div>
                    </Link>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className={styles.content}>
          {isSale && (
            <div
              className={cn(styles.bannersContainer, {
                [styles.rows]: secondLevel.banners.length < 3,
              })}
            >
              {secondLevel.banners?.map((item, index) => {
                let view: BannerGalleryProps['view'] = 'fullscreen';

                if (secondLevel.banners.length > 2) {
                  view = (index + 3) % 3 === 0 ? 'fullscreen' : 'halfscreen';
                }

                return (
                  <CardInView
                    key={item.title}
                    className={styles.saleBanner}
                    id={item.title}
                    listTitle='menu'
                    position={index}
                    cardType='promo'
                    card={item}
                    targetPathname={item.link}
                  >
                    <SaleBanner banner={item} view={view} onClickLink={onClickLink} />
                  </CardInView>
                );
              })}
            </div>
          )}

          {isRooms && <Rows items={secondLevel.children} onClickLink={handleClickRooms} />}

          {isCategories && (
            <div className={styles.contentContainer}>
              <div>
                <Link
                  className={styles.title}
                  animated={!!secondLevel.url}
                  to={secondLevel.url}
                  view='primary'
                  onClick={(e) => handleClickCategory(e, secondLevel.name)}
                >
                  {secondLevel.name}
                </Link>
                <div
                  className={cn(styles.columns, { [styles.mini]: secondLvlItems.items.length < 3 })}
                >
                  {secondLvlItems.items.length < 3 ? (
                    <>
                      {secondLvlItems.items.map((subitem) => {
                        const title = `${secondLevel.name}/${subitem.name}`;

                        return (
                          <div className={styles.list} key={subitem.id}>
                            <Link
                              className={cn(styles.subtitle, { [styles.pointer]: !!subitem.url })}
                              animated={!!subitem.url}
                              to={subitem.url}
                              view='primary'
                              onClick={(e) => subitem.url && handleClickCategory(e, title)}
                            >
                              {subitem.name}
                            </Link>
                            <div className={styles.sublist}>
                              {subitem.children.map((link) => {
                                const titleUrl = `${secondLevel.name}/${subitem.name}/${link.name}`;

                                return (
                                  <Link
                                    key={link.id}
                                    className={styles.link}
                                    to={link.url}
                                    onClick={(e) => link.url && handleClickCategory(e, titleUrl)}
                                  >
                                    {link.name}
                                  </Link>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {columns.map((column, index) => (
                        <div key={index} className={styles.column}>
                          {column.map((subitem) => {
                            const title = `${secondLevel.name}/${subitem.name}`;

                            return (
                              <div className={styles.list} key={subitem.id}>
                                <Link
                                  className={cn(styles.subtitle, {
                                    [styles.pointer]: !!subitem.url,
                                  })}
                                  animated={!!subitem.url}
                                  to={subitem.url}
                                  view='primary'
                                  onClick={(e) => subitem.url && handleClickCategory(e, title)}
                                >
                                  {subitem.name}
                                </Link>
                                <div className={styles.sublist}>
                                  {subitem.children.map((link) => {
                                    const titleUrl = `${secondLevel.name}/${subitem.name}/${link.name}`;

                                    return (
                                      <Link
                                        key={link.id}
                                        className={styles.link}
                                        to={link.url}
                                        onClick={(e) =>
                                          link.url && handleClickCategory(e, titleUrl)
                                        }
                                      >
                                        {link.name}
                                      </Link>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ))}
                    </>
                  )}
                </div>
                {secondLvlItems.common.length > 0 && (
                  <div className={styles.commonLinks}>
                    {secondLvlItems.common.map((common) => (
                      <Link
                        key={common.id}
                        className={styles.commonLink}
                        to={common.url}
                        view='primary'
                        animated
                        onClick={(e) => handleClickCategory(e, common.name)}
                      >
                        {common.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
              <div>
                {matchedPromotion && (
                  <SingleBanner
                    className={styles.banner}
                    promotion={matchedPromotion}
                    onClickLink={onClickLink}
                  />
                )}

                {secondLvlItems.banners.length > 0 && (
                  <div className={styles.banners}>
                    {secondLvlItems.banners.map((miniBanner) => (
                      <Link
                        key={miniBanner.id}
                        className={styles.bannerContainer}
                        style={{ background: miniBanner.parameters?.backgroundColor }}
                        to={miniBanner.url}
                        onClick={(e) => handleClickMiniBanner(e, miniBanner)}
                      >
                        <div
                          style={{
                            width: `${
                              miniBanner.parameters?.iconSize?.width || DEFAULT_ICON_SIZE
                            }px`,
                            height: `${
                              miniBanner.parameters?.iconSize?.height || DEFAULT_ICON_SIZE
                            }px`,
                            flex: `0 0 ${
                              miniBanner.parameters?.iconSize?.width || DEFAULT_ICON_SIZE
                            }px`,
                          }}
                        >
                          <Img
                            src={miniBanner.icon?.url}
                            className={styles.bannerIcon}
                            alt='banner-icon'
                          />
                        </div>

                        <div>
                          <div className={styles.bannerName}>{miniBanner.name}</div>
                          <div className={styles.bannerDescription}>{miniBanner.subtitle}</div>
                          {miniBanner.parameters?.linkText && (
                            <Link underlined view='primary' className={styles.bannerLink}>
                              {miniBanner.parameters.linkText}
                            </Link>
                          )}
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}

          {isNovelty && (
            <PromoGrid
              type='novelty'
              promotions={promotions?.novelty || []}
              onClickLink={onClickLink}
            />
          )}

          {isPromotions && (
            <PromoGrid
              type='promotions'
              promotions={promotions?.promotions || []}
              onClickLink={onClickLink}
            />
          )}
        </div>
      </div>
    </DropDownWrapper>
  );
});

DropDown.displayName = 'DropDown';

export default memo(DropDown);
