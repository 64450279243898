import { useMutation } from '@tanstack/react-query';

import ApiCart from '@Api/Cart';
import { useRequest } from '@Contexts/Request';
import { useDeps } from '@Contexts/DI';
import useCartKeys from './useCartKeys';

import type { CartData, UpdatedCartInfo } from '@Types/Cart';
import type { Params } from '@Api/Cart/update';

function useChangePositionCount() {
  const { queryClient } = useRequest();
  const keys = useCartKeys();
  const { analytics } = useDeps();

  return useMutation<UpdatedCartInfo, Error, Params>({
    mutationFn: (params) => {
      const { cartPositionId, quantity, signal } = params;
      return ApiCart.update({ cartPositionId, quantity, signal });
    },
    onSuccess: (data, { cartPositionId }) => {
      const prevQuantity =
        queryClient
          .getQueryData<CartData>(keys)
          .positions.find((position) => position.id === cartPositionId)?.quantity || 0;

      const newCart: CartData = queryClient.setQueryData(keys, (prev: CartData) => ({
        ...prev,
        ...data.cart,
        ...data,
        newPositions: prev.newPositions,
      }));

      const position = newCart.positions.find(
        (currentPosition) => currentPosition.id === cartPositionId,
      );

      if (position.quantity > prevQuantity)
        analytics.dispatchEvent('cart.add', {
          products: [{ ...position.products[0], quantity: position.quantity - prevQuantity }],
          label: 'Изменение количества',
        });
      else if (position.quantity < prevQuantity)
        analytics.dispatchEvent('cart.remove', {
          products: [{ ...position.products[0], quantity: prevQuantity - position.quantity }],
          label: 'Изменение количества',
        });
    },
  });
}

export default useChangePositionCount;
