import { memo } from 'react';
import { cn } from '@divlab/divanui';

import SecondLevelItem from '../SecondLevelItem';
import styles from './SecondLevelNav.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';
import type { MainMenuItem } from '@Types/Layout';

export interface SecondLevelNavProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  items: MainMenuItem[];
  onClickItem?: (e: MouseEvent) => void;
}

const SecondLevelNav: FC<SecondLevelNavProps> = (props) => {
  const { className, items, onClickItem, ...restProps } = props;

  return (
    <div {...restProps} className={cn(styles.nav, className)}>
      {items.map((menuItem) => (
        <SecondLevelItem key={menuItem.id} menuItem={menuItem} onClick={onClickItem} />
      ))}
    </div>
  );
};

export default memo(SecondLevelNav);
