import { useState, useCallback, useEffect, useRef } from 'react';
import loadable from '@loadable/component';

import * as PageLock from '@Stores/PageLock';
import * as ModalWindows from '@Stores/ModalWindows';
import useNavigation from '@Navigation/useNavigation';
import ErrorBoundary from '@Components/ErrorBoundary';
import { useDeps } from '@Contexts/DI';
import useKeyboardEvents from '@Hooks/useKeyboardEvents';

import type { FC } from 'react';
import type { AbstractModal, Modal } from '@Stores/ModalWindows/typings';

export interface Props {
  className?: string;
  modal: Modal;
  onClose?: (e: MouseEvent) => void;
}

const actions = [
  {
    regexp: /^#authorization$/,
    fn: () => ModalWindows.open('Authorization'),
  },
  {
    regexp: /^#videoconsultation$/,
    fn: () => ModalWindows.open('VideoConsultation'),
  },
  {
    regexp: /^#instagram-confirm$/,
    fn: () => {
      ModalWindows.open('Info', { view: 'success', variant: 'consent-publication-photos' });
    },
  },
  {
    regexp:
      /^(#loyalty-program-rules|#loyalty-program-rules-bonuses|#divan-club-rules|#mebel-club-rules)$/,
    fn: (matches) => {
      ModalWindows.open('StaticInfo', {
        slug: 'divan-club-rules',
        hash: window.location.hash,
        section: (matches[0] as string).slice(0),
      });
    },
  },
  {
    regexp: /^#configuration-([0-9]{1,})$/,
    fn: (matches) => ModalWindows.open('Constructor', { productId: +matches[1] }),
  },
  {
    regexp: /^#instagram-post-([0-9]{1,})$/,
    fn: (matches) => ModalWindows.open('InstagramPost', { selectedId: `${matches[1]}` }),
  },
  {
    regexp: /^#offer-legal-entity$/,
    fn: () => ModalWindows.open('StaticInfo', { slug: 'offer-legal-entity' }),
  },
  {
    regexp: /^#oferta$/,
    fn: () => ModalWindows.open('StaticInfo', { slug: 'oferta' }),
  },
  {
    regexp: /^#personal-data-agreement$/,
    fn: () =>
      ModalWindows.open('StaticInfo', {
        slug: 'personal-data-agreement',
      }),
  },
  {
    regexp: /^#privacy-policy$/,
    fn: () => ModalWindows.open('StaticInfo', { slug: 'privacy-policy' }),
  },
  {
    regexp: /^#terms-of-payment$/,
    fn: () => {
      ModalWindows.open('StaticInfo', { slug: 'terms-of-payment' }, { needToReplace: false });
    },
  },
  {
    regexp: /^#get-disposal-info$/,
    fn: () => {
      ModalWindows.open('FurnitureDisposal');
    },
  },
  {
    regexp: /^(#module-editor){1}(&model-(\d+)|&configuration-(\d+)|&fabric-(\d+)|&code-(\D+))*$/g,
    fn: (matches) => {
      ModalWindows.open('ModuleConstructor', {
        modelId: matches[0].match(/&model-(\d+)/)?.[1],
        fabricId: matches[0].match(/&fabric-(\d+)/)?.[1],
        configurationId: matches[0].match(/&configuration-(\d+)/)?.[1],
        code: matches[0].match(/&code-(\D+)/)?.[1],
        source: matches[0].match(/&code-(\D+)/)?.[1],
      });
    },
  },
  {
    regexp: /^#recommendation-technologies-rules$/,
    fn: () => ModalWindows.open('StaticInfo', { slug: 'recommendation-technologies-rules' }),
  },
  {
    regexp: /^#gift-certificate-priority-terms$/,
    fn: () => ModalWindows.open('StaticInfo', { slug: 'gift-certificate-priority-terms' }),
  },
];

function handleOpenByEvent(event: Event) {
  const modalInfo = (event as CustomEvent<AbstractModal>).detail;
  ModalWindows.open(modalInfo.id, modalInfo.data, { lowPriority: true });
}

const Loader = loadable((props: Props) => import(`@Modals/${props.modal.id}Modal/index`));

const ModalWindowsManager: FC = () => {
  const modals = ModalWindows.useModals();
  const visibleModals = ModalWindows.useVisibleModals();
  const currentModal = ModalWindows.useCurrentModal();
  const { analytics, eventManager } = useDeps();
  const [key, setKey] = useState(1);
  const visibleModalsIds = useRef<Modal['id'][]>([]);
  const { pathname } = useNavigation();

  const handleError = useCallback(() => {
    ModalWindows.clear();
    ModalWindows.open('Info', { view: 'error', variant: 'unknown-error' });

    setKey((prev) => prev + 1);
  }, []);

  /**
   * Opens the modal windows when load the page and match the hash
   */
  const handleChangeHash = useCallback(() => {
    const { hash } = window.location;

    const [firstMatchedAction] = actions
      .map((action) => ({
        ...action,
        matches: hash.match(action.regexp),
      }))
      .filter((action) => !!action.matches);

    if (firstMatchedAction) firstMatchedAction.fn(firstMatchedAction.matches);
  }, []);

  const handlePopstate = useCallback(() => {
    ModalWindows.closeAll();
  }, []);

  useEffect(() => {
    if (visibleModals.length > 0) {
      PageLock.add('modal');
    } else {
      PageLock.remove('modal');
    }
    return () => {
      PageLock.remove('modal');
    };
  }, [visibleModals.length]);

  useEffect(() => {
    setTimeout(() => {
      handleChangeHash();
    }, 200);
    window.addEventListener('changeHash', handleChangeHash);
    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('changeHash', handleChangeHash);
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [handleChangeHash, handlePopstate]);

  useKeyboardEvents({
    onEscape: () => {
      if (!currentModal) return;

      analytics.dispatchEvent('modals', {
        modalId: currentModal.id,
        action: 'close',
        actionReason: 'escape',
        data: currentModal.data,
      });
      ModalWindows.close(currentModal.id);
    },
  });

  useEffect(() => {
    window.addEventListener('modalWindows.open', handleOpenByEvent);

    return () => {
      window.removeEventListener('modalWindows.open', handleOpenByEvent);
    };
  }, []);

  useEffect(() => {
    const openedModals = visibleModals.filter(
      (modalInStore) => !visibleModalsIds.current.includes(modalInStore.id),
    );

    openedModals.forEach((modal) => {
      // Timeout is added to dispatch various user actions events (such as click or Esc press)
      // before dispatching an event of opening a modal window
      setTimeout(() => {
        analytics.dispatchEvent('modals', {
          modalId: modal.id,
          action: 'open',
          data: modal.data,
        });
      });
    });

    const visibleModalsInStoreIds = visibleModals.map((modalInStore) => modalInStore.id);

    const closedModalsIds = visibleModalsIds.current.filter(
      (modalId) => !visibleModalsInStoreIds.includes(modalId),
    );

    closedModalsIds.forEach((modalId) => {
      const modalData = modals.stack.find((modal) => modal.id === modalId);

      if (!modalData) return;

      analytics.dispatchEvent('modals', {
        modalId,
        action: 'close',
        data: modalData.data,
      });
    });

    visibleModalsIds.current = visibleModalsInStoreIds;
  }, [visibleModals, analytics, modals.stack]);

  // clear all modals if pathname was changed
  useEffect(() => {
    ModalWindows.clear();
  }, [pathname]);

  // Отправляем событие каждый раз, когда меняется список
  // видимых модальных окон
  useEffect(() => {
    eventManager.dispatch('modalWindows.changeVisibleModals', visibleModals);
  }, [eventManager, visibleModals]);

  return (
    <ErrorBoundary key={key} onError={handleError}>
      {modals.stack.map((modal) => (
        <Loader
          key={modal.id}
          modal={modal}
          onClose={() => {
            ModalWindows.close(modal.id);
          }}
        />
      ))}
    </ErrorBoundary>
  );
};

export default ModalWindowsManager;
