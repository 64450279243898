import { useMutation } from '@tanstack/react-query';

import ApiCart from '@Api/Cart';
import OrderFormStore from '@Stores/OrderForm/OrderFormStore';
import { useRequest } from '@Contexts/Request';
import { useDeps } from '@Contexts/DI';

import type { CartModalInputProduct, CartProductData } from '@Types/Cart';
import type { ProductData } from '@Types/Product';

export interface Variables {
  products: CartModalInputProduct[];
  options: { isRelated: boolean };
  analyticsData: {
    products: Partial<ProductData>[] | Partial<CartProductData>[];
    label?: string;
  };
  commercialGuid?: string;
}

export const useAddToCart = () => {
  const { queryClient } = useRequest();
  const { analytics } = useDeps();

  return useMutation<unknown, Error, Variables>({
    mutationFn: async ({ products, commercialGuid }) => {
      const res = await ApiCart.put({ products, commercialGuid });

      return res;
      // return transformCart({ cart: res, options });
    },
    onSuccess: (cart, { products, analyticsData }) => {
      const paymentTypeId = products.find((product) => product.paymentTypeId)?.paymentTypeId;

      if (paymentTypeId) {
        OrderFormStore.select({ paymentType: paymentTypeId });
      }

      const bankOffer = products.find((product) => product.bankOffer)?.bankOffer;

      // queryClient.setQueryData(keys, (prev: CartData) => ({ ...prev, ...cart }));
      queryClient.invalidateQueries({ queryKey: ['cartShortInfo'], refetchType: 'all' });

      analytics.dispatchEvent('cart.add', {
        products: analyticsData.products,
        label: bankOffer || analyticsData.label,
      });
    },
  });
};

export default useAddToCart;
