import * as Api from '@Api/index';

interface Params {
  url: string;
  targetRegion?: string;
  targetLanguage?: string;
}

/**
 * Проверяет, ведёт ли путь на страницу-исключение
 * @param path относительный путь без слагов языка и региона
 * @returns значение типа `boolean`, где `true` означает, что страница входит в список исключений
 *
 * Например:
 * ```
 * console.log(isToExceptionPage('/category/divany-i-kresla/page-2?sort=1')); // выведет true
 * console.log(isToExceptionPage('/product/divan-ines-vertical-terra#authorization')); // выведет false
 * ```
 */
function isToExceptionPage(path: string) {
  // Очищаем путь от search и hash и разбиваем на чанки
  const pathnameChunks = path.toLowerCase().split('#')[0].split('?')[0].split('/').filter(Boolean);

  // Проверяем по списку страниц-исключений
  const toMain = pathnameChunks.length === 0;
  const toCategory = pathnameChunks[0] === 'category';
  const toSitemap = `/${pathnameChunks[0]}/${pathnameChunks[1]}` === '/site/site-map-full';
  const toShowroom = `/${pathnameChunks[0]}/${pathnameChunks[1]}` === '/site/showroom';
  const toContacts = `/${pathnameChunks[0]}/${pathnameChunks[1]}` === '/site/contacts';
  const toDelivery = `/${pathnameChunks[0]}/${pathnameChunks[1]}` === '/site/delivery';

  return [toMain, toCategory, toSitemap, toShowroom, toContacts, toDelivery].some(Boolean);
}

/**
 * Функция трансформирования url
 * @param params - содержит url, в котором необходимо добавить/заменить префиксы с языком и регионом,
 * и целевые значения языка и региона
 */
function transformUrl(params: Params) {
  const { url, targetRegion, targetLanguage } = params;
  const { supportedLanguages, defaultLanguage, language, allRegions, mainRegion, region } =
    Api.getRequest();

  // Никак не преобразовываем любые внешние ссылки
  if (
    !url ||
    url.startsWith('#') ||
    url.startsWith('tel') ||
    url.startsWith('http:') ||
    url.startsWith('https:') ||
    url.startsWith('mailto:')
  ) {
    return url;
  }

  const chunks = url.split('/');

  // Удаляем из ссылки указанный язык и регион
  const filteredChunks = chunks.filter((chunk) => {
    return ![...supportedLanguages, ...allRegions].includes(chunk.toLowerCase());
  });

  // Определяем языковой слаг
  const languageSlug = targetLanguage === undefined ? language.slug : targetLanguage;

  // Определяем региональный слаг
  let regionSlug = '';

  if (isToExceptionPage(filteredChunks.join('/')))
    regionSlug = targetRegion === undefined ? region : targetRegion;

  // Формируем ссылку, в которой мы гарантируем корректность выбранного языка и региона
  const trueChunks = [
    languageSlug === defaultLanguage ? '' : languageSlug,
    regionSlug === mainRegion ? '' : regionSlug,
    ...filteredChunks,
  ].filter(Boolean);

  if (trueChunks[trueChunks.length - 1] === regionSlug) trueChunks.push('');

  const trueUrl = `/${trueChunks.join('/')}`;

  return trueUrl;
}

export default transformUrl;
