import { useQuery } from '@tanstack/react-query';

import * as ApiPromo from '@Api/Promo';
import { useDeps } from '@Contexts/DI';
import { useRequest } from '@Contexts/Request';
import { useDevPanel } from '@Stores/DevPanel';

import type { UseQueryResult } from '@tanstack/react-query';
import type { MenuPromotionsData } from '@Promo/typings';

function usePromotionsForMenu(): UseQueryResult<MenuPromotionsData> {
  const { logger } = useDeps();
  const { currentDate: devCurrentDate } = useDevPanel();
  const currentDate = devCurrentDate || new Date();
  const { country, region, language } = useRequest();
  const timestamp = currentDate?.getTime();

  const keys = ['menu-promotions', { country, lang: language.id, region }];

  const result = useQuery({
    queryKey: keys,
    queryFn: async () => {
      try {
        const menuPromotions = await ApiPromo.getPromotionsForMenu({
          country,
          lang: language.id,
          region,
          currentDate: timestamp,
        });

        return menuPromotions || {};
      } catch (err) {
        // Этот запрос мы считаем не критичным, если произошла ошибка,
        // то допустимо отдать пустой набор данных, чтобы сохранить доступность страницы
        logger.log(err);

        return Promise.resolve({});
      }
    },
    enabled: true,
    placeholderData: (previousData) => previousData,
  });

  return result;
}

export default usePromotionsForMenu;
