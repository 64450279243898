import { useExperiments } from 'yandex-metrica-ab-react';

import { useRequest } from '@Contexts/Request';
import useRenderType from '@Hooks/useRenderType';

const ABHook = () => {
  const {
    abtYandex: { clientId },
  } = useRequest();
  useExperiments({
    clientId,
  });
  return null;
};

export const YandexAB = () => {
  const { isCSR } = useRenderType();

  return isCSR && <ABHook />;
};
