import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

export interface AdditionalData {
  id: number;
  quantity: number;
}

export interface ModuleData {
  id: number;
  quantity: number;
}

export interface Params {
  productIds: number[];
  isSetConfigurator?: boolean;
  opts?: {
    additional?: AdditionalData[];
    module?: ModuleData[];
  };
}

async function getBonuses(params: Params) {
  const { productIds, isSetConfigurator } = params;
  const searchParams = new URLSearchParams();

  searchParams.set('ids', productIds.toString());

  if (isSetConfigurator) searchParams.set('isSetConfigurator', isSetConfigurator.toString());

  const url = `/cabinet/cart/get-bonus-earned-amount?${searchParams.toString()}`;
  const res = await Api.queryProxi<any>(url, { method: 'POST', body: JSON.stringify(params.opts) });

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getBonuses;
