import * as Meta from '@Queries/Meta';
import Link from '@Navigation/Link';
import { phoneToLink } from '@Utils/phone';

import type { FC } from 'react';
import type { LinkProps } from '@Navigation/Link';

const Phone: FC<LinkProps> = (props) => {
  const { children, ...restProps } = props;
  const meta = Meta.useMeta();

  if (!meta.isSuccess || !meta.data.phones[0]) return null;

  const phone = phoneToLink(meta.data.phones[0]);

  return (
    <Link {...restProps} to={phone} data-testid='callback-mobile'>
      {children}
    </Link>
  );
};

export default Phone;
